import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Image from '../components/image';
import Layout from '../components/layout';
import Link from '../components/link';
import SEO from '../components/SEO';
import SectionArticleTeaser from '../sections/section-article-teaser';
import SectionOpener from '../sections/section-opener/section-opener';
import { buildLink, resolve } from '../urls';

class InsightsPage extends Component {
  componentDidMount() {
    const overlayTeasers = document.querySelectorAll('.section-article-teaser--white');

    // remove the padding top from all the 'overlay' teasers except from the first one
    // to reduce the space between them
    if (overlayTeasers.length) {
      overlayTeasers.forEach((teaser, i) => {
        if (i !== 0) {
          teaser.style.paddingTop = 0;
        }
      });
    }
  }

  render() {
    const { data, location } = this.props;

    const {
      contentfulLayout: page,
      headerTeasersNodes: { edges: headerTeasers },
      newsEventsNodes: { edges: newsEvents },
      insightsNodes: { edges: insightsTeasers },
      interviewsNodes: { edges: interviewTeasers },
      articlesNodes: { edges: articleTeasers },
    } = data;

    const idsToRemove = headerTeasers.map(({ node: { contentful_id } }) => contentful_id);

    const extractor = (nodeArray, arrayLength) =>
      nodeArray
        .map(({ node }) => node) // extract the object from the node main object
        .filter(
          ({ contentful_id }) => !idsToRemove.includes(contentful_id) // remove the article if the id is the same of one of the 3 header teasers (no dublicates on the body page)
        )
        .splice(0, arrayLength); // keeps only the first N entries
    const pageWrapClasses = classNames({
      page: true,
      [`page--${page.contentfulid}`]: true,
    });

    const RecentTeaser = ({ node, i }) => (
      <div className="insight-teaser-list__recent-item" key={i}>
        <article className="insight-teaser">
          <Link
            to={resolve('insights:detail', { insightSlug: node.slug })}
            className="insight-teaser__link"
          >
            <div className="insight-teaser__image-wrapper">
              {node.main_image && node.main_image.file && (
                <div className="insight-teaser__image">
                  <Image image={node.main_image} alt={node.main_image.title} />
                </div>
              )}
            </div>
            <div className="insight-teaser__text">
              {node.post_category && (
                <span className="insight-teaser__category">{node.post_category}</span>
              )}

              {node.title && (
                <span className="insight-teaser__title" itemProp="name">
                  {node.title}
                </span>
              )}
            </div>
          </Link>
        </article>
      </div>
    );

    return (
      <div className={pageWrapClasses}>
        <Layout>
          <SEO title={page.title} metadata={page.metadata} />
          <SectionOpener>
            {/* Page Header */}
            <div className="insight-teaser-list__recent">
              <div className="insight-teaser-list__recent-full">
                {headerTeasers.map(({ node }, i) =>
                  i === 0 ? <RecentTeaser node={node} i={i} key={i} /> : null
                )}
              </div>
              <div className="insight-teaser-list__recent-half">
                {headerTeasers.map(({ node }, i) =>
                  i > 0 ? <RecentTeaser node={node} i={i} key={i} /> : null
                )}
              </div>
            </div>
          </SectionOpener>
          {/* Teaser Sections */}
          {newsEvents && newsEvents.length > 0 && (
            <SectionArticleTeaser
              link={buildLink(
                'See more News & Events',
                resolve('pages:detail', { pageSlug: '/news-events/page/1' })
              )}
              label="Latest News & Events"
              reading={extractor(newsEvents, 3)}
              buttonText="See more News & Events"
              buttonSlug="/news-events/page/1"
              type="small"
            />
          )}

          {insightsTeasers && insightsTeasers.length > 0 && (
            <SectionArticleTeaser
              link={buildLink(
                'See more Insights',
                resolve('pages:detail', { pageSlug: '/insights/page/1' })
              )}
              label="Our latest Insights"
              reading={extractor(insightsTeasers, 3)}
              buttonText="See more Insights"
              buttonSlug="/insights/page/1"
              whiteBg
              type="overlay"
            />
          )}

          {interviewTeasers && interviewTeasers.length > 0 && (
            <SectionArticleTeaser
              link={buildLink(
                'See more Interviews',
                resolve('pages:detail', { pageSlug: '/interviews/page/1' })
              )}
              label="Our latest Interviews"
              reading={extractor(interviewTeasers, 3)}
              buttonText="See more Interviews"
              buttonSlug="/interviews/page/1"
              whiteBg
              type="overlay"
            />
          )}

          {articleTeasers && articleTeasers.length > 0 && (
            <SectionArticleTeaser
              link={buildLink(
                'See more News',
                resolve('pages:detail', { pageSlug: '/article/page/1' })
              )}
              label="Our latest Thoughts & Opinions"
              reading={extractor(articleTeasers, 6)}
              buttonText="See more News"
              buttonSlug="/article/page/1"
              type="regular"
              withSubline
              noLabel
            />
          )}
        </Layout>
      </div>
    );
  }
}

export default InsightsPage;

export const pageQuery = graphql`
  query {
    contentfulLayout(contentfulid: { eq: "insights" }) {
      title
      contentfulid
      metadata {
        ...Metadata
      }
    }
    headerTeasersNodes: allContentfulMagazine(
      filter: { post_category: { ne: null }, published_date: { ne: null } }
      sort: { fields: [published_date], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          ...allMagazinesFragments
        }
      }
    }
    newsEventsNodes: allContentfulMagazine(
      filter: { post_category: { in: ["Events", "News"] }, published_date: { ne: null } }
      sort: { fields: [published_date], order: DESC }
      limit: 6
    ) {
      edges {
        node {
          ...allMagazinesFragments
        }
      }
    }
    insightsNodes: allContentfulMagazine(
      filter: { post_category: { eq: "Insights" }, published_date: { ne: null } }
      sort: { fields: [published_date], order: DESC }
      limit: 6
    ) {
      edges {
        node {
          ...allMagazinesFragments
        }
      }
    }
    interviewsNodes: allContentfulMagazine(
      filter: { post_category: { eq: "Interviews" }, published_date: { ne: null } }
      sort: { fields: [published_date], order: DESC }
      limit: 6
    ) {
      edges {
        node {
          ...allMagazinesFragments
        }
      }
    }
    articlesNodes: allContentfulMagazine(
      filter: { post_category: { eq: "Article" }, published_date: { ne: null } }
      sort: { fields: [published_date], order: DESC }
      limit: 9
    ) {
      edges {
        node {
          ...allMagazinesFragments
        }
      }
    }
  }
`;

InsightsPage.propTypes = {
  children: PropTypes.node,
  navbarIsInverted: PropTypes.bool,
};
